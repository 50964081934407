import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Quicklinks from '../../components/Quicklinks';
import OutboundLink from '../../components/OutboundLink';
import StudentQuote from '../../components/StudentQuote';

const FAQs = () => (
  <Layout title="Frequently Asked Questions" className="annual-challenge">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-orange"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../annual-challenge/about-the-challenge/">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb active">Frequently Asked Questions</li>
        </ul>
        <h1 className="page-title">Frequently Asked Questions</h1>
        <Image filename="hero-faqs.png" className="hero-image" />
      </div>
    </div>
    {/* Quick Links */}
    <Section>
      <Container>
        <Row>
          <Column size={11} offset={1}>
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#entry',
                  label: 'Entry',
                },
                {
                  target: '#judging',
                  label: 'Judging',
                },
                {
                  target: '#summermentorship',
                  label: 'Summer Mentorship',
                },
                {
                  target: '#finalevent',
                  label: 'Final Event',
                },
                {
                  target: '#prizes',
                  label: 'Prizes',
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Entry Questions */}
    <Section id="entry" className="faq-accordion mb-2">
      <Container className="faq-accordion__header">
        <Row>
          <Column offset={2}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists?years=2024">
              <h2>
                Meet the Finalists of the 2024 3M Young Scientist Challenge!
              </h2>
            </Link>
          </Column>
        </Row>
        <Row>
          <Column size={1} offset={2}>
            <Image filename="icon-faq-entryquestions.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={3} offset={2}>
            <h2>Entry Questions</h2>
          </Column>
          <Column size={4}>
            <p>
              Get all the answers you need to key questions about the 3M Young
              Scientist Challenge.
            </p>
          </Column>
        </Row>
      </Container>
      <Container className="faq-accordion__questions">
        <Row>
          <Column size={6} offset={4}>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    How do students enter?
                  </label>
                  <div className="content">
                    To enter, students need to submit a 1–2-minute video in
                    which they describe a new innovation or solution that could
                    solve or impact an everyday problem that directly affects
                    them, their families, their communities, and/or the global
                    population. Get ideas from this year’s{' '}
                    <Link to="/annual-challenge/entry-topics">
                      entry topics.
                    </Link>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    Is the competition open to anyone?
                  </label>
                  <div className="content">
                    The competition is open to all legal U.S. residents who are
                    students enrolled in 5th through 8th grade at a public,
                    private, parochial, or home school located in one of the
                    fifty United States or the District of Columbia.
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    Why do parents have to grant permission for students to
                    enter?
                  </label>
                  <div className="content">
                    In compliance with the Children's Online Privacy Protection
                    Act of 1998, Parental/Guardian consent is needed for any
                    person under the age of 13 years to give personal
                    information to a third party over the internet. In the case
                    of our competition, each student's parent/guardian needs to
                    grant consent for us to receive their entry.
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck4"></input>
                  <label className="label" for="chck4">
                    Can students enter as pairs or teams?
                  </label>
                  <div className="content">
                    Students may not submit as pairs or in groups—only
                    individual submissions will be accepted.
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck5"></input>
                  <label className="label" for="chck5">
                    Can music be used in my video?
                  </label>
                  <div className="content">
                    Entry videos may not contain music unless created by the
                    student or by someone who has given the entrant permission
                    to use his/her music.
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck6"></input>
                  <label className="label" for="chck6">
                    Where can students get a video camera?
                  </label>
                  <div className="content">
                    Many cell phones and digital cameras can be used to film a
                    short video. Students who don't have access to either can
                    check with their teacher, school media center, or local
                    public library.
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck7"></input>
                  <label className="label" for="chck7">
                    How can students retrieve a lost username/password?
                  </label>
                  <div className="content">
                    When a student's parent/guardian completed the required
                    consent form and registration form, the student's username
                    and a link to change their password were emailed to the
                    student and his/her parent/guardian. Passwords can also be
                    reset{' '}
                    <OutboundLink
                      to="https://dlc.com/3MYSC-Application"
                      target="_blank"
                    >
                      here
                    </OutboundLink>
                    .
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck8"></input>
                  <label className="label" for="chck8">
                    Are there rules and guidelines about how to make a video?
                  </label>
                  <div className="content">
                    Yes, you can find video submission instructions and entry
                    topics{' '}
                    <OutboundLink to="/annual-challenge/entry-topics">
                      here
                    </OutboundLink>
                    . It is also very important that each student reads and
                    complies with the Official Rules and Regulations and Safety
                    Guidelines. The official rules for the 2024 3M Young
                    Scientist Challenge are posted{' '}
                    <OutboundLink to="https://dlc.com/3MYSC-rules">
                      here
                    </OutboundLink>
                    .
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck9"></input>
                  <label className="label" for="chck9">
                    Are there examples of successful videos that can be viewed?
                  </label>
                  <div className="content">
                    Helpful{' '}
                    <OutboundLink to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-ChallengeVideoTips.pdf">
                      challenge tips
                    </OutboundLink>{' '}
                    and{' '}
                    <Link to="/annual-challenge/finalists-mentors-judges/finalists?years=2022#innovators">
                      previous winners' videos
                    </Link>{' '}
                    are available to inspire your entry. Note: Entry criteria
                    changes each year, so please read the guidelines carefully.
                  </div>
                </div>
                {/* <div className="accordion__item">
                  <input type="checkbox" id="chck10"></input>
                  <label className="label" for="chck10">
                    Are there other resources available?
                  </label>
                  <div className="content">
                    Check out the{' '}
                    <Link to="/annual-challenge/challenge-resources">
                      Challenge Resources page.
                    </Link>{' '}
                  </div>
                </div> */}
                <div className="accordion__item">
                  <input type="checkbox" id="chck11"></input>
                  <label className="label" for="chck11">
                    How do students submit a video?
                  </label>
                  <div className="content">
                    <p>
                      To apply, students must create a profile in the 3M Young
                      Scientist Challenge Application Portal with the help of a
                      parent or guardian. Once a student's parent/guardian has
                      completed the consent form and registration page the
                      student will be able to submit a video file (MP4 format).
                      Before uploading your video, be sure it meets the
                      following criteria:
                    </p>
                    <ol>
                      <li>
                        Your video only contains your original ideas and work.
                      </li>
                      <li>
                        Your video does not have copyrighted or trademarked
                        content.
                      </li>
                      <li>
                        Please make sure the title of your video is "3MYSC
                        Submission—[Your Title]".
                      </li>
                      <li>Your video is between 1-2 minutes in length.</li>
                    </ol>
                    <p>
                      Any video that does not meet this criteria will be
                      disqualified from the challenge. Questions? Email us at{' '}
                      <OutboundLink
                        to="mailto:ysc@discoveryed.com"
                        target="_blank"
                      >
                        YSC@DiscoveryEd.com
                      </OutboundLink>
                    </p>
                  </div>
                </div>
                {/* <div className="accordion__item">
                  <input type="checkbox" id="chck12"></input>
                  <label className="label" for="chck12">
                    Can submitted videos be updated?
                  </label>
                  <div className="content">
                    Yes, videos can be updated prior to the entry deadline of
                    May 2, 2024 at 11:59pm ET. If a student is interested in
                    making updates, please reach out to ysc@discoveryed.com for
                    instructions.
                  </div>
                </div> */}
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Judging Questions */}
    <Section id="judging" className="faq-accordion mb-2">
      <Container className="faq-accordion__header">
        <Row>
          <Column size={1} offset={2}>
            <Image filename="icon-faq-judging.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={4} offset={2}>
            <h2>Judging Questions</h2>
          </Column>
        </Row>
      </Container>
      <Container className="faq-accordion__questions">
        <Row>
          <Column size={6} offset={4}>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck13"></input>
                  <label className="label" for="chck13">
                    How are videos judged?
                  </label>
                  <div className="content">
                    <p>
                      Videos do NOT need to be "produced" or have high
                      production value. Judges are not evaluating production
                      skills. Videos may be recorded on cell phones or simple
                      digital cameras, for example. In addition, local libraries
                      and schools may be able to loan cameras to students.
                    </p>
                    <p>
                      Enries that follow all of the rules and regulations will
                      be ranked based on the average of scores awarded by a
                      panel of judges using the following rubric:
                    </p>
                    <ul>
                      <li>
                        Creativity (ingenuity and innovative thinking) (30%)
                      </li>
                      <li>Scientific knowledge (30%)</li>
                      <li>Persuasiveness and effective communication (20%)</li>
                      <li>Overall presentation (20%)</li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck14"></input>
                  <label className="label" for="chck14">
                    Who are the judges?
                  </label>
                  <div className="content">
                    The video competition is judged by a field of experts in
                    science and communication including current and former
                    teachers, scientists, and representatives from school
                    districts across the country.
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck15"></input>
                  <label className="label" for="chck15">
                    When and how will students find out if they have won?
                  </label>
                  <div className="content">
                    Students will be contacted via phone and/or email in early
                    June if they are selected as a Finalist. State Merit Winners
                    will be notified in mid-June.
                  </div>
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Summer Mentorship Questions */}
    <Section id="summermentorship" className="faq-accordion mb-2">
      <Container className="faq-accordion__header">
        <Row>
          <Column size={1} offset={2}>
            <Image filename="icon-faq-mentorship.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={4} offset={2}>
            <h2>Summer Mentorship Questions</h2>
          </Column>
        </Row>
      </Container>
      <Container className="faq-accordion__questions">
        <Row>
          <Column size={6} offset={4}>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck16"></input>
                  <label className="label" for="chck16">
                    What is the summer mentorship program?
                  </label>
                  <div className="content">
                    Finalists will receive a summer assignment to develop an
                    innovation to be completed under the mentorship of a 3M
                    scientist.
                  </div>
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Final Events Questions */}
    <Section id="finalevent" className="faq-accordion mb-2">
      <Container className="faq-accordion__header">
        <Row>
          <Column size={1} offset={2}>
            <Image filename="icon-faq-finalevent.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={4} offset={2}>
            <h2>Final Event Questions</h2>
          </Column>
        </Row>
      </Container>
      <Container className="faq-accordion__questions">
        <Row>
          <Column size={6} offset={4}>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <form>
                    <input type="checkbox" id="chck17"></input>
                    <label className="label" for="chck17">
                      What happens at the Final Event?
                    </label>
                    <div className="content">
                      Ten student finalists will each give a presentation based
                      on their work in the summer mentorship program, and
                      participate in a series of scored challenges to
                      demonstrate their scientific knowledge. The event is
                      anticipated to take place in October each year, but is
                      subject to change.
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Prizing Questions */}
    <Section id="prizes" className="faq-accordion mb-2">
      <Container className="faq-accordion__header">
        <Row>
          <Column size={1} offset={2}>
            <Image filename="icon-faq-prizing.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={4} offset={2}>
            <h2>Prizing Questions</h2>
          </Column>
        </Row>
      </Container>
      <Container className="faq-accordion__questions">
        <Row>
          <Column size={6} offset={4}>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck18"></input>
                  <label className="label" for="chck18">
                    What are the student prizes?
                  </label>
                  <div className="content">
                    <p>
                      At the end of the competition, America's Top Young
                      Scientist will be awarded $25,000, and other student
                      winners will receive exciting prizes.
                    </p>
                    <p>Sample prizing for winners includes:</p>
                    <p className="text-bold">
                      Honorable Mention (up to 4 awarded—one from each grade
                      band)
                    </p>
                    <ul>
                      <li>
                        3M Young Scientist Challenge swag bag and a 3M Young
                        Scientist Challenge Honorable Mention certificate.
                      </li>
                    </ul>
                    <p className="text-bold">
                      State Merit Winners (up to 51—one from each State and the
                      District of Columbia)
                    </p>
                    <ul>
                      <li>A Prize Pack</li>
                    </ul>
                    <p className="text-bold">Finalists (10)</p>
                    <ul>
                      <li>
                        Participation in a unique summer mentorship with a 3M
                        scientist
                      </li>
                      <li>A Prize Pack</li>
                      <li>
                        A trip to the Final Event for the student and ONE
                        parent/guardian
                      </li>
                      <li>$1,000</li>
                      <li>A Contest Medal</li>
                      <li>
                        An invitation to join the 3M Young Scientist Challenge
                        Alumni Network, which brings together Finalists and
                        mentors from the first year of the 3M and Discovery
                        Education partnership in 2008 through the latest
                        Challenge year.
                      </li>
                    </ul>

                    <p className="text-bold">Two Runner-Up Prizes</p>
                    <ul>
                      <li>
                        $2,000 to be applied to an adventure of the winner’s
                        choice{' '}
                      </li>
                    </ul>
                    <p className="text-bold">Grand Prize</p>
                    <ul>
                      <li>$25,000</li>
                      <li>
                        $2,000 to be applied to an adventure of the winner’s
                        choice{' '}
                      </li>
                      <li>A Contest Trophy</li>
                      <li>
                        The title of "America's Top Young Scientist 2024"{' '}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Student Stories Quote */}
    <Section className="student-stories">
      <StudentQuote
        image="Kyle-Tianshi-2020-Finalist-Quote.png"
        name="Kyle Tianshi"
        year="2020 3M Young Scientist Challenge Finalist"
        body="I believe that young people, with the right guidance, have the drive and
      passion to make a big impact on the world."
        path="/annual-challenge/finalists-mentors-judges/finalists/kyle-tianshi"
        classNames="bg-gradient-orange cta__link no-arrow"
        calloutText="Learn what sparked Kyle’s journey"
      ></StudentQuote>
    </Section>
  </Layout>
);

export default FAQs;
